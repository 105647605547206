const CreateRequestConfiguration = (method, with_cookies = true, body = null) => {
  let config = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (with_cookies) {
    config["credentials"] = "include";
  }

  if (body != null) {
    config["body"] = JSON.stringify(body);
  }

  return config;
};

async function PerformRequest(address, configuration) {
  let res = await fetch(address, configuration);
  if (!res.ok) {
    throw res.status;
  }

  let res_json = await res.json();
  if (!res_json) {
    return;
  }
  if (typeof res_json === "object" && "result" in res_json) {
    return res_json.result;
  } else {
    return res_json;
  }
}

export async function get(url) {
  const config = CreateRequestConfiguration("GET");

  return await PerformRequest(url, config);
}

export async function post(url, body = null) {
  const config = CreateRequestConfiguration("POST", true, body);

  return await PerformRequest(url, config);
}
export async function put(url, body = null) {
  const config = CreateRequestConfiguration("PUT", true, body);

  return await PerformRequest(url, config);
}

export async function http_delete(url, body = null) {
  const config = CreateRequestConfiguration("DELETE", true, body);

  return await PerformRequest(url, config);
}
