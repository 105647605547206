import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
import Waitlist from "./Waitlist";
import TryIt from "./TryIt";

const App = () => {
  const [currWindow, setCurrWindow] = useState("landing");

  const changeCurrWindow = (window) => {
    setTimeout(() => {
      setCurrWindow(window);
    }, 50);
  };

  const renderWindow = () => {
    switch (currWindow) {
      case "contact-us":
        return (
          <ContactUs
            onLinkClicked={(window) => {
              changeCurrWindow(window);
            }}
          />
        );

      case "try-it":
        return <TryIt />;

      case "waitlist":
        return (
          <Waitlist
            onLinkClicked={(window) => {
              changeCurrWindow(window);
            }}
          />
        );

      default: //landing
        return (
          <>
            <Main />
            <Footer />
          </>
        );
    }
  };

  return (
    <div className={`App${currWindow === "landing" ? " landing" : ""}`}>
      <Header
        onLinkClicked={(window) => {
          changeCurrWindow(window);
        }}
      />
      {renderWindow()}
    </div>
  );
};

export default App;
