import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, InputGroup } from "react-bootstrap";
import Button from "./Button";
import Format from "../common/format";
import { ReactComponent as Back } from "../images/back.svg";
import { post } from "../common/requests";

const getValidationProps = (value, formik, onlyInvalid = false) => {
  if (onlyInvalid) {
    return {
      isInvalid: formik.touched[value] && formik.errors[value],
    };
  }
  return {
    isValid: formik.touched[value] && !formik.errors[value],
    isInvalid: formik.touched[value] && formik.errors[value],
  };
};

const ContactUs = ({ onLinkClicked }) => {
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [serverError, setServerError] = useState(null);

  const initialValues = {
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    company: "",
    job_title: "",
    how_can_we_help: "",
  };

  const schema = yup.object().shape({
    email: yup.string().required(),
    phone: yup.string().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    company: yup.string().required(),
    job_title: yup.string().required(),
    how_can_we_help: yup.string().required("Please provide a message"),
  });

  const handleSubmit = async (values) => {
    if (serverError) {
      setServerError(null);
    }
    const { email, first_name, last_name, phone, company, job_title, audio_needs, how_can_we_help } = values;
    const url = `${process.env.REACT_APP_DOGPATCH_API_ADDRESS}/contact_us`;
    const body = { email, first_name, last_name, phone, company, job_title, audio_needs, how_can_we_help };
    try {
      const res = await post(url, body);
      setRequestSubmitted(true);
    } catch (errorCode) {
      setServerError(`Error ${errorCode}. Please try again later`);
    }
  };

  const renderContactUs = () => {
    if (!requestSubmitted) {
      return (
        <>
          <div className="t-form__heading">Contact Us</div>
          <div className="t-form">
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
              {(formik) => (
                <Form id="contact-us-form" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="t-form__row">
                    <Form.Group className="mb-3">
                      <Form.Label className="t-form__label dark">Email*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("email", formik)}
                        {...formik.getFieldProps("email")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.email)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="t-form__label dark">Phone*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("phone", formik)}
                        {...formik.getFieldProps("phone")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.phone)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="t-form__row">
                    <Form.Group className="mb-3">
                      <Form.Label className="t-form__label dark">First name*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("first_name", formik)}
                        {...formik.getFieldProps("first_name")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.first_name)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="t-form__label dark">Last name*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("last_name", formik)}
                        {...formik.getFieldProps("last_name")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.last_name)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="t-form__row">
                    <Form.Group className="mb-4">
                      <Form.Label className="t-form__label dark">Company name*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("company", formik)}
                        {...formik.getFieldProps("company")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.company)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4">
                      <Form.Label className="t-form__label dark">Job title*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("job_title", formik)}
                        {...formik.getFieldProps("job_title")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.job_title)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="t-form__label dark">
                      How much audio do you need to enhance monthly?*
                    </Form.Label>
                    <Form.Select className="t-form__select dark" size="lg" {...formik.getFieldProps("audio_needs")}>
                      <option>Please select</option>
                      <option value="0-100">0-100 Hours</option>
                      <option value="101-1,000">101-1,000 Hours</option>
                      <option value="1,001-10,000">1,001-10,000 Hours</option>
                      <option value="10,001-100,000">10,001-100,000 Hours</option>
                      <option value="100,001+">100,001+ Hours</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className="t-form__label dark">How can we help?*</Form.Label>
                    <Form.Control
                      className="t-form__control dark"
                      size="lg"
                      as="textarea"
                      rows={3}
                      {...getValidationProps("how_can_we_help", formik)}
                      {...formik.getFieldProps("how_can_we_help")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Format.capitalize(formik.errors.how_can_we_help)}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form>
              )}
            </Formik>
            <div className="t-form__error">{serverError}</div>
            <Button form="contact-us-form" type="submit" white className="t-form__button">
              Submit
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div className="u-center-text">
          <div className="t-form__submitted--heading">Thank you!</div>
          <div className="t-form__submitted--subheading">We will get back to you shortly</div>
          <Back className="t-form__submitted--back" onClick={() => onLinkClicked("landing")} />
        </div>
      );
    }
  };

  return <div className="contact-us">{renderContactUs()}</div>;
};

export default ContactUs;
