import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Zoom } from "../images/zoom.svg";
import { ReactComponent as Teams } from "../images/teams.svg";
import { ReactComponent as Meet } from "../images/meet.svg";
import { ReactComponent as Slack } from "../images/slack.svg";
import { ReactComponent as Discord } from "../images/discord.svg";
import { ReactComponent as Twitch } from "../images/twitch.svg";
import YC from "../images/YC.png";

const Footer = () => {
  const [isMediumScreen, setIsMediumScreen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const checkScreenSize = () => {
    setIsMediumScreen(window.matchMedia("(max-width: 768px)").matches);
    setIsSmallScreen(window.matchMedia("(max-width: 430px)").matches);
  };

  const renderLogos = () => {
    if (isSmallScreen) {
      return (
        <div className="footer__logos-line">
          <Zoom />
          <Teams />
          <Meet />
        </div>
      );
    } else if (isMediumScreen) {
      return (
        <>
          <div className="footer__logos-line">
            <Zoom />
            <Teams />
            <Meet />
          </div>
          <div className="footer__logos-line">
            <Slack />
            <Discord />
            <Twitch />
          </div>
        </>
      );
    } else {
      return (
        <>
          <Zoom />
          <Teams />
          <Meet />
          <Slack />
          <Discord />
          <Twitch />
        </>
      );
    }
  };

  return (
    <div className="footer">
      <div className="footer__tagline">Works with any application</div>
      <div className="footer__logos">{renderLogos()}</div>
      <img src={YC} alt="YC" className="yc" />
    </div>
  );
};

export default Footer;
