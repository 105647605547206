import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Logo } from "../images/logo-white.svg";
import Button from "./Button";

const Header = ({ onLinkClicked }) => {
  const [logoWidth, setLogoWidth] = useState(125);

  useEffect(() => {
    const headerAction = document.querySelector(".header__action");
    if (headerAction) {
      setLogoWidth(headerAction.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div className="header">
      <div className="header__logo-wrapper" style={{ width: logoWidth }} onClick={() => onLinkClicked("landing")}>
        {/* <Logo /> */}
        <div className="tailorr">Tailorr</div>
      </div>
      <div className="header__links">
        {/* <Button link className="header--link" onClick={() => onLinkClicked("how-it-works")}>
          How it works
        </Button>
        <Button link className="header--link" onClick={() => onLinkClicked("pricing")}>
          Pricing
        </Button>
        <Button link className="header--link" onClick={() => onLinkClicked("contact-us")}>
          Contact us
        </Button> */}
      </div>
      <div className="header__action">
        <Button link onClick={() => onLinkClicked("contact-us")}>
          Contact us
        </Button>
        <Button white onClick={() => onLinkClicked("waitlist")}>
          Join waitlist
        </Button>
      </div>
    </div>
  );
};

export default Header;
