const Format = {
  float: (value, num_digits) => {
    if (value === Math.floor(value)) {
      return value;
    } else {
      return value.toFixed(num_digits);
    }
  },
  capitalize: (str) => {
    if (str) {
      return (str.charAt(0).toUpperCase() + str.slice(1)).replace("_", " ");
    }
  },
  subscriptionStatus: (status) => {
    switch (status) {
      case "Active":
        return { status, color: "green" };

      case "Inactive":
        return { status, color: null };

      case "Trialing":
        return { status: "In Trial", color: "orange" };

      case "Canceled":
        return { status, color: "red" };

      default:
        break;
    }
  },
  date: (date, showWeekday = false, locale = "en-US") => {
    let options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    if (showWeekday) {
      options.weekday = "long";
    }
    return date.toLocaleDateString(locale, options);
  },
  http: (value) => {
    let formatted = value.trim().toLowerCase();
    if (!formatted.startsWith("http")) {
      return "https://" + formatted;
    } else {
      return formatted;
    }
  },
};

export default Format;
