import React from "react";

const properties = ["white", "alert", "small", "big", "link", "primary"];

const Button = (props) => {
  let propsMutable = { ...props };
  let className = "t-button";

  properties.map((property) => {
    if (props[property]) {
      className += ` t-button--${property}`;
      delete propsMutable[property];
    }
  });
  if (props.className) {
    className += " " + props.className;
  }
  if (props.disabled) {
    propsMutable.onClick = () => {};
  }
  if (props.Ref) {
    propsMutable.ref = props.Ref;
    delete propsMutable["Ref"];
  }

  return (
    <button {...propsMutable} className={className}>
      {props.children}
    </button>
  );
};

export default Button;
