import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { Form, InputGroup } from "react-bootstrap";
import Button from "./Button";
import Format from "../common/format";
import { ReactComponent as Back } from "../images/back.svg";
import { post } from "../common/requests";

const getValidationProps = (value, formik, onlyInvalid = false) => {
  if (onlyInvalid) {
    return {
      isInvalid: formik.touched[value] && formik.errors[value],
    };
  }
  return {
    isValid: formik.touched[value] && !formik.errors[value],
    isInvalid: formik.touched[value] && formik.errors[value],
  };
};

const Waitlist = ({ onLinkClicked }) => {
  const [requestSubmitted, setRequestSubmitted] = useState(false);
  const [serverError, setServerError] = useState(null);

  const initialValues = {
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    how_did_you_hear: "",
  };

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    company: yup.string().required(),
    how_did_you_hear: yup.string(),
  });

  const handleSubmit = async (values) => {
    if (serverError) {
      setServerError(null);
    }
    const { email, first_name, last_name, company, how_did_you_hear } = values;
    const url = `${process.env.REACT_APP_DOGPATCH_API_ADDRESS}/waitlist`;
    const body = { email, first_name, last_name, company, how_did_you_hear };
    try {
      const res = await post(url, body);
      setRequestSubmitted(true);
    } catch (errorCode) {
      if (errorCode === 409) {
        setServerError("Email already in waitlist");
      } else {
        setServerError(`Error ${errorCode}. Please try again later`);
      }
    }
  };

  const renderWaitlist = () => {
    if (!requestSubmitted) {
      return (
        <>
          <div className="t-form__heading">Join Waitlist</div>
          <div className="t-form">
            <Formik initialValues={initialValues} validationSchema={schema} onSubmit={handleSubmit}>
              {(formik) => (
                <Form id="waitlist-form" noValidate onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="t-form__row">
                    <Form.Group className="mb-3">
                      <Form.Label className="t-form__label dark">First name*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("first_name", formik)}
                        {...formik.getFieldProps("first_name")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.first_name)}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="t-form__label dark">Last name*</Form.Label>
                      <Form.Control
                        className="t-form__control dark"
                        size="lg"
                        type="text"
                        {...getValidationProps("last_name", formik)}
                        {...formik.getFieldProps("last_name")}
                      />
                      <Form.Control.Feedback type="invalid">
                        {Format.capitalize(formik.errors.last_name)}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="t-form__label dark">Email*</Form.Label>
                    <Form.Control
                      className="t-form__control dark"
                      size="lg"
                      type="text"
                      {...getValidationProps("email", formik)}
                      {...formik.getFieldProps("email")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Format.capitalize(formik.errors.email)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className="t-form__label dark">Company name*</Form.Label>
                    <Form.Control
                      className="t-form__control dark"
                      size="lg"
                      type="text"
                      {...getValidationProps("company", formik)}
                      {...formik.getFieldProps("company")}
                    />
                    <Form.Control.Feedback type="invalid">
                      {Format.capitalize(formik.errors.company)}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Label className="t-form__label dark">How did you hear about us?</Form.Label>
                    <Form.Control
                      className="t-form__control dark"
                      size="lg"
                      type="text"
                      {...getValidationProps("how_did_you_hear", formik)}
                      {...formik.getFieldProps("how_did_you_hear")}
                    />
                  </Form.Group>
                </Form>
              )}
            </Formik>
            <div className="t-form__error">{serverError}</div>
            <Button form="waitlist-form" type="submit" white className="t-form__button">
              Submit
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <div className="u-center-text">
          <div className="t-form__submitted--heading">Welcome to Tailorr!</div>
          <div className="t-form__submitted--subheading">We will email you as soon as your account is ready</div>
          <Back className="t-form__submitted--back" onClick={() => onLinkClicked("landing")} />
        </div>
      );
    }
  };

  return <div className="waitlist">{renderWaitlist()}</div>;
};

export default Waitlist;
