import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Play } from "../images/play.svg";
import { ReactComponent as Pause } from "../images/pause.svg";
import Tal from "../images/Tal-min.png";
import { ReactComponent as Audio } from "../images/audiowave.svg";
import originalAudio from "../audio/original.mp3";
import processedAudio from "../audio/processed.mp3";
import Button from "./Button";
import { Switch } from "@mui/material";

const Main = () => {
  const [enhancedAudio, setEnhancedAudio] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioSource, setAudioSource] = useState(originalAudio);
  const [switchTouched, setSwitchTouched] = useState(false);
  const audioRef = useRef(null);
  const currentTimeRef = useRef(0);

  useEffect(() => {
    const audioElement = audioRef.current;
    if (isPlaying) {
      audioElement.currentTime = currentTimeRef.current;
      audioElement.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      if (audioElement.currentTime !== 0) {
        currentTimeRef.current = audioElement.currentTime;
      }
      audioElement.pause();
    }

    const onEnded = () => {
      setIsPlaying(false);
      audioElement.currentTime = 0;
      currentTimeRef.current = 0;
      setSwitchTouched(false);
      setEnhancedAudio(false);
    };

    audioElement.addEventListener("ended", onEnded);

    return () => {
      audioElement.removeEventListener("ended", onEnded);
    };
  }, [isPlaying, audioSource]);

  const switchAudio = () => {
    if (!switchTouched) {
      setSwitchTouched(true);
    }
    setEnhancedAudio(!enhancedAudio);
    const audioElement = audioRef.current;
    currentTimeRef.current = audioElement.currentTime;

    if (audioSource === originalAudio) {
      setAudioSource(processedAudio);
    } else {
      setAudioSource(originalAudio);
    }
  };

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const checkScreenSize = () => {
    setIsSmallScreen(window.matchMedia("(max-width: 600px)").matches);
  };

  const renderSubheading = () => {
    if (isSmallScreen) {
      return (
        <div className="main__subheading-line">
          Tailorr is an AI-powered voice platform that helps non-native speakers communicate better by enhancing
          pronunciation in real-time.
        </div>
      );
    } else {
      return (
        <>
          <div className="main__subheading-line">
            Tailorr is an AI-powered voice platform that helps non-native speakers
          </div>
          <div className="main__subheading-line">communicate better by enhancing pronunciation in real-time.</div>
        </>
      );
    }
  };

  return (
    <div className="main">
      <div className="main__tagline">increase your speech intelligibility with ai</div>
      <div className="main__heading">
        <div className="main__heading-line">Redefining communication</div>
        <div className="main__heading-line">for non-native speakers</div>
      </div>
      <div className="main__subheading">{renderSubheading()}</div>
      <div className="main__action">
        <Button
          // className="main__action-play"
          white
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            setIsPlaying(!isPlaying);
          }}
        >
          {isPlaying ? (
            <Pause className="main__action-pause" />
          ) : (
            <Play className="main__action-play" style={{ margin: "0 3px" }} />
          )}
          {isPlaying ? "Pause" : "Play"} demo
        </Button>
        {/* <Button link className="main__try-it">
          Try it yourself
        </Button> */}
      </div>
      <div className="main__diagram">
        <div className="main__diagram-audio">
          <Audio className={`audiowave${isPlaying && !enhancedAudio ? " active" : ""}`} />
          <span className={isPlaying && !enhancedAudio ? "show" : "hide"}>Original</span>
        </div>
        <div className="main__diagram-action">
          <img src={Tal} alt="person" height={66} width={66} style={{ marginBottom: "0.5rem" }} />
          {/* <Microphone />
          <Sparkles style={{ visibility: isPlaying && checked ? "visible" : "hidden" }} /> */}
          <Switch color="primary" checked={enhancedAudio} onChange={switchAudio} />
          {isPlaying && !switchTouched && (
            <div className="arrow">
              <span></span>
            </div>
          )}
        </div>
        <div className="main__diagram-audio">
          <Audio className={`audiowave${isPlaying && enhancedAudio ? " active" : ""}`} />
          <span className={isPlaying && enhancedAudio ? "show" : "hide"}>Enhanced</span>
        </div>
        {/* <Speaker /> */}
      </div>
      <audio ref={audioRef} src={audioSource} />
    </div>
  );
};

export default Main;
